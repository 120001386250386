import { Fragment, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { ThemeChanger } from '../redux/modules/theme/action'
import desktopLogo from '../assets/images/brand-logos/desktop-logo.png'
import { LocalStorageBackup } from '../components/common/switcher/switcherdata/switcherdata'
import { auth } from './firebaseapi'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { PORTAL_USER_TYPES } from '@common/src/constants/constants'

const Login = ({ ThemeChanger }) => {
  const { loading } = useSelector((state) => state.auth)
  const [passwordshow1, setPasswordshow1] = useState(false)
  const toast = useToast()
  const [data, setData] = useState({
    email: '',
    password: '',
  })
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [browserName, setBrowserName] = useState('');
  const [showRecommendation, setShowRecommendation] = useState(false);

  // Detect the browser
  const detectBrowser = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Edg')) {
      return 'Microsoft Edge';
    } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
      return 'Google Chrome';
    } else if (userAgent.includes('Firefox')) {
      return 'Mozilla Firefox';
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      return 'Safari';
    } else if (userAgent.includes('OPR') || userAgent.includes('Opera')) {
      return 'Opera';
    } else if (userAgent.includes('Trident') || userAgent.includes('MSIE')) {
      return 'Internet Explorer';
    }

    return 'Unknown Browser';
  };

  // Show the message based on the browser
  useEffect(() => {
    const detectedBrowser = detectBrowser();
    setBrowserName(detectedBrowser);

    const chromeRecommendationShown = localStorage.getItem('chrome-recommendation-shown');

    if (!chromeRecommendationShown) {
      setShowRecommendation(true);
    }
  }, []);

  // Dismiss the message and save preference in localStorage
  const dismissRecommendation = () => {
    setShowRecommendation(false);
    localStorage.setItem('chrome-recommendation-shown', 'true');
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (values) => {
      login()
    },
  })

  const changeHandler = (e) => {
    const { name, value } = e.target
    setData((prevData) => ({ ...prevData, [name]: value }))
    formik.handleChange(e)
  }

  const navigate = useNavigate()
  const routeChange = () => {
    const path = `${import.meta.env.BASE_URL}dashboard`
    navigate(path)
  }

  const login = async (e) => {
    if (e) e.preventDefault()

    const errors = await formik.validateForm()
    if (Object.keys(errors).length > 0) {
      // Show validation errors
      formik.setTouched({ email: true, password: true })
      return
    }

    setIsLoggingIn(true)

    try {
      await signInWithEmailAndPassword(auth, data.email, data.password)

      const currentUser = auth.currentUser
      if (!currentUser) {
        throw new Error('Authentication failed. User not found.')
      }

      const idTokenResult = await currentUser.getIdTokenResult(true)
      const customClaims = idTokenResult?.claims

      if (PORTAL_USER_TYPES[import.meta.env.VITE_PORTAL]?.includes(customClaims.userType)) {
        toast.success('Login successful. Welcome back!')
        routeChange() // Navigate to the next page
      } else {
        throw new Error('Unauthorized user type.')
      }
    } catch (error) {
      await signOut(auth)
      toast.error('Login failed. Please check your credentials and try again.')
    } finally {
      setIsLoggingIn(false)
    }
  }

  useEffect(() => {
    LocalStorageBackup(ThemeChanger)
  }, [])

  return (
    <Fragment>
      {showRecommendation && (
        <div className="fixed top-4 right-4 bg-gradient-to-r from-blue-50 via-blue-100 to-blue-200 border border-blue-300 p-4 rounded-lg shadow-md z-50 max-w-sm">
          <div className="flex justify-between items-center">
            <p className="text-blue-900 font-medium">
              {browserName === 'Google Chrome' ? (
                <>
                  You are using <strong>{browserName}</strong>.
                </>
              ) : (
                <>
                  You are using <strong>{browserName}</strong>. For the best experience, please use Google Chrome to
                  access the admin portal.
                </>
              )}
            </p>
            <button
              onClick={dismissRecommendation}
              aria-label="Close"
              className="text-blue-600 hover:text-blue-900 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="mt-2 flex items-center space-x-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                onChange={() => localStorage.setItem('chrome-recommendation-shown', 'true')}
                className="w-4 h-4 text-blue-500 border-blue-300 rounded focus:ring-blue-400"
              />
              <span className="text-blue-800">Don't show again</span>
            </label>
          </div>
        </div>
      )}

      <div className="container">
        <div className="flex justify-center authentication authentication-basic items-center h-full text-defaultsize text-defaulttextcolor">
          <div className="grid grid-cols-12">
            <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
            <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-8 col-span-12">
              <div className="my-[1.5rem] flex justify-center">
                  <img src={desktopLogo} alt="logo" className="desktop-logo" />
                  <img src={desktopLogo} alt="logo" className="desktop-dark" />
              </div>
              <div className="box !p-[3rem]">
                <div
                  className="box-body"
                  role="tabpanel"
                  id="pills-with-brand-color-01"
                  aria-labelledby="pills-with-brand-color-item-1"
                >
                  <p className="h5 font-semibold mb-2 text-center">Sign In</p>
                  <form onSubmit={login}>
                    <div className="grid grid-cols-12 gap-y-4">
                      <div className="xl:col-span-12 col-span-12">
                        <label htmlFor="signin-username" className="form-label text-default">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          className={`form-control form-control-lg w-full !rounded-md ${
                            formik.touched.email && formik.errors.email ? 'border-danger' : ''
                          }`}
                          onChange={changeHandler}
                          onBlur={formik.handleBlur}
                          value={data.email}
                          id="signin-username"
                          placeholder="Email"
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger mt-1">{formik.errors.email}</div>
                        )}
                      </div>
                      <div className="xl:col-span-12 col-span-12 mb-2">
                        <label htmlFor="signin-password" className="form-label text-default block">
                          Password
                          <Link to={`${import.meta.env.BASE_URL}forgot`} className="float-end text-danger">
                            Forgot password ?
                          </Link>
                        </label>
                        <div className="input-group">
                          <input
                            type={passwordshow1 ? 'text' : 'password'}
                            className={`form-control form-control-lg !rounded-s-md ${
                              formik.touched.password && formik.errors.password ? 'border-danger' : ''
                            }`}
                            name="password"
                            placeholder="Password"
                            value={data.password}
                            onChange={changeHandler}
                            onBlur={formik.handleBlur}
                          />
                          <button
                            onClick={() => setPasswordshow1(!passwordshow1)}
                            aria-label="button"
                            className="ti-btn ti-btn-light !rounded-s-none !mb-0"
                            type="button"
                          >
                            <i className={`${passwordshow1 ? 'ri-eye-line' : 'ri-eye-off-line'} align-middle`}></i>
                          </button>
                        </div>
                        {formik.touched.password && formik.errors.password && (
                          <div className="text-danger mt-1">{formik.errors.password}</div>
                        )}
                      </div>
                      <div className="xl:col-span-12 col-span-12 grid mt-2">
                        <button
                          type="submit"
                          disabled={loading}
                          className="ti-btn ti-btn-primary !bg-primary !text-white !font-medium"
                        >
                          {loading || isLoggingIn ? <span className="me-2">Loading </span> : `Sign In`}
                          {(loading || isLoggingIn) && (
                            <span className="loading">
                              <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  localVariable: state.theme,
})

export default connect(mapStateToProps, { ThemeChanger })(Login)
