import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AddProductButton from '../../../components/common/buttons/AddProductButton';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { DAY_ABBREVIATIONS, PRODUCT_ADD } from '@hub/constants/constants';
import { normalizeDateFormat } from '@common/src/common/helper';
import TimedAvailability from '@hub/components/common/availability/TimedAvailability';
import { availabilityValidationSchema } from '@hub/components/common/availability/validationSchema';

const validationSchema = Yup.object({
  ...availabilityValidationSchema(),
});

const Availability = ({
  handleNext,
  handleBack,
  setProductData,
  productData,
  isEdit,
  setEditProductData,
  editProductData,
  setDisableNavigation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isBackLoading, setIsBackLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    dateStart: null,
    dateEnd: null,
    days: [],
    timeStart: '',
    timeEnd: '',
    timed: false,
    dateAll: false,
    timeAll: false,
    daysAll: false,
  });

  useEffect(() => {
    // Set initial values based on productData
    setInitialValues({
      dateStart: productData?.availability?.date?.start || null,
      dateEnd: productData?.availability?.date?.end || null,
      days:
        productData?.availability?.day?.days?.map((day) =>
          Object.keys(DAY_ABBREVIATIONS).find((key) => DAY_ABBREVIATIONS[key] === day)
        ) || [],
      timeStart: productData?.availability?.time?.start || '',
      timeEnd: productData?.availability?.time?.end || '',
      timed: productData?.timed || false,
      dateAll: productData?.availability?.date?.option === PRODUCT_ADD.ALL,
      timeAll: productData?.availability?.time?.option === PRODUCT_ADD.ALL,
      daysAll: productData?.availability?.day?.option === PRODUCT_ADD.ALL,
    });
  }, [productData]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    setDisableNavigation(true);
    await submitLogic(values);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setTimeout(() => {
      setIsLoading(false);
      setDisableNavigation(false);
      handleNext();
    }, 1000);
  };

  const submitLogic = async (values) => {
    // Determine the day option
    let dayOption;
    if (values.days.length === 7) {
      dayOption = PRODUCT_ADD.ALL;
      values.daysAll = true;
    } else {
      values.daysAll = false;
      dayOption = PRODUCT_ADD.SOME;
    }

    // Convert selected days to abbreviations
    const selectedDays = values.days.map((day) => DAY_ABBREVIATIONS[day]);

    // Construct the availability object
    const timed = values.timed;
    const availability =
      timed && {
        date: {
          start: normalizeDateFormat(values.dateStart),
          end: normalizeDateFormat(values.dateEnd),
          option: values.dateAll ? PRODUCT_ADD.ALL : PRODUCT_ADD.RANGE,
        },
        day: {
          days: selectedDays,
          option: dayOption,
        },
        time: {
          start: values.timeStart,
          end: values.timeEnd,
          option: values.timeAll ? PRODUCT_ADD.ALL : PRODUCT_ADD.RANGE,
        },
      };
    const updatedData = { ...productData, availability, timed, availableToday: !timed, availableDate: {
      start: null,
      end: null,
    }, };
    if (isEdit) {
      setEditProductData({ availability, ...editProductData, timed });
      setProductData((prevData) => ({ ...prevData, availability,timed}));
    } else {
      setProductData(updatedData);
    }
  };

  const handleBackWithLoader = async () => {
    setIsBackLoading(true); // Set loading state to true
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate async operation
      handleBack(); // Call the handleBack function
    } catch (error) {
      console.error('Error during back operation:', error);
    } finally {
      setIsBackLoading(false); // Always reset loading state
    }
  };
  

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values)
                }}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <Form>
                    {/* Timed toggle */}
                    <TimedAvailability errors={errors} touched={touched} />

                    <AddProductButton
                      handleBack={handleBackWithLoader} // Pass the updated back handler
                      handleNext={() => handleSubmit(values)} // Pass the next handler
                      loadingBack={isBackLoading} // Loader state for Back button
                      loadingNext={isLoading} // Loader state for Next button
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Availability.propTypes = {
  handleNext: PropTypes.func.isRequired,
  setProductData: PropTypes.func.isRequired,
  productData: PropTypes.object,
  isEdit: PropTypes.bool,
  setEditProductData: PropTypes.func,
  editProductData: PropTypes.object,
  handleBack: PropTypes.func,
  setDisableNavigation: PropTypes.func
};

export default Availability;