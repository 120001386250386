import loaderImg from './loader.svg'

const LoaderSm = () => {
  return (

    <div className='flex items-center justify-center w-full'>
      <img src={loaderImg} alt='' className='w-10'/>
    </div>
  )
}

export default LoaderSm