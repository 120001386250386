import React from 'react'
import PropTypes from 'prop-types'
import SelectBox from '../selectbox/selectbox'

const FilterDropdown = ({ label, options, value, onChange,className, labelClassName }) => (
    <div className={className}>
        {label && <label className={labelClassName}>{label}</label>}
        <SelectBox options={options} value={value} onChange={onChange}  />
    </div>
)

FilterDropdown.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
}

export default FilterDropdown
