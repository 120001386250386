import { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import CropImage from '@common/src/common/ImageUpload/CropImage'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { modifierAction } from '@common/src/service/cloud/catalog'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { IMG_UPLOAD, STATUS_OPTIONS } from '@hub/constants/constants'
import PreviewImage from '@hub/components/common/ImageHolders/previewImage';



const ModifierForm = ({ id, data, isEdit, onComplete, companyId }) => {
  const toast = useToast()
  const cropImageRef = useRef(null)
  const [isImage, setIsImage] = useState(!!data?.image);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: {},
    description: {},
    imageUrl: '',
    posId: '',
    sortOrder: '',
    status: '',
    image: ''
  })

  const getSingleModifierData = async () => {
    // const result = await getSingleCompanyDoc(27, CATALOG.MODIFIER_COLLECTION, 100)
    // const modifierData = result.data
    console.log(data)

    setInitialValues({
      name: data?.modItemName || {},
      description: data?.description || {},
      posId: data?.posId || '',
      sortOrder: data?.sortOrder || '',
      status: data?.status || STATUS_OPTIONS[0].value,
      image: data?.image || ''
    })
  }

  useEffect(() => {
    getSingleModifierData()
  }, [])
  const validationSchema = Yup.object({
    name: Yup.object()
      .shape({
        en: Yup.string().required('English name is required'),
        ar: Yup.string().required('Arabic name is required'),
      })
      .required('Name is required'),
    description: Yup.object()
      .shape({
        en: Yup.string().required('English description is required'),
        ar: Yup.string().required('Arabic description is required'),
      })
      .required('Description object is required'),
    posId: Yup.string().required('POS ID is required'),
    status: Yup.string().required('Status is required'),
    sortOrder: Yup.number()
      .typeError('Sort order is required')
      .min(1, 'Sort order must be greater than zero')
      .required('Sort order is required'),
    image: !isImage && Yup.mixed()
      .test('image', 'Image is required', (value) => value?.name !== undefined)
      .test('file-format', 'Only jpeg, png, or jpg formats are allowed', function (value) {
        if (!value) return true; // Skip validation if no file is uploaded
        const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/jpg'];
        return SUPPORTED_FORMATS.includes(value.type); // Check the file type
      })
      .test('file-size', 'File size must be less than 1MB', function (value) {
        if (!value) return true; // Skip validation if no file is uploaded
        const MAX_SIZE = 1 * 1024 * 1024; // 1MB in bytes
        return value.size <= MAX_SIZE; // Check the file size
      })
  })
  const onUploadSuccess = async ({ values, actions, url = '' }) => {

    actions?.setSubmitting(true)
    setIsLoading(true)
    const formData = {
      posId: values.posId,
      sortOrder: values.sortOrder,
      tags: 'chicken',
      modItemName: values.name,
      description: values.description,
      image: url || values?.image,
      modStatus: values.status,
      status: values.status,
      companyId: parseInt(companyId),
      ...(isEdit && { id: parseInt(data.id) })
    }
    try {
      await modifierAction(formData, isEdit)
      const toastMessage = isEdit ? 'Modifier updated successfully!' : 'Modifier added successfully!'
      toast.success(toastMessage)
      actions?.resetForm()
      setIsLoading(false)
      onComplete?.()
    } catch (error) {
      setIsLoading(false)
      toast.error(isEdit ? 'Error updating Modifier. Please ensure that the POS ID is unique.' : 'Error creating Modifier. Please ensure that the POS ID is unique.')
      console.error('Form Submission Error:', error)
    }
    finally {
      setIsLoading(false)
    }
  }
  const handleSubmit = async (values, actions) => {
    setIsLoading(true);
    if (cropImageRef.current) {
      cropImageRef.current.uploadImage()
    } else {
      await onUploadSuccess({ values, actions })
    }

  }
  const onUploadError = (error) => {
    toast.error('Error uploading image')
    onComplete?.()
  }
  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      autoClose={false}
      loading={isLoading}
      id={id}
    >
      {({ setFieldValue, values, errors, touched }) => {
        return (<div className="grid grid-cols-12 gap-6 ">
          <div className="xl:col-span-12 col-span-12">
            <div className="box">
              <div className="box-body add-products !p-0">
                <div className="grid grid-cols-1 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <MultiLanguageInput
                      type="input"
                      label="Modifier Name"
                      id="product-name-add"
                      name="name"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-3 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="POS ID"
                      name="posId"
                      type="text"
                      placeholder="POS ID"
                      value={values.posId}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/^\s+/, ''); // Remove leading spaces
                        setFieldValue('posId', sanitizedValue); // Update Formik's value
                      }}
                      errorText={errors.posId && touched.posId ? errors.posId : null}
                      required
                      maxLength={10}
                    />
                    {/* <ErrorMessage name="posId" component="div" style={{ color: 'red' }} /> */}
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Sort Order"
                      name="sortOrder"
                      type="number"
                      placeholder="Sort Order"
                      value={values.sortOrder}
                      onChange={(e) => setFieldValue('sortOrder', parseFloat(e.target.value))}
                      errorText={errors.sortOrder && touched.sortOrder ? errors.sortOrder : null}
                      required
                    />
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      label="Status"
                      name="status"
                      options={STATUS_OPTIONS}
                      value={STATUS_OPTIONS.find((option) => option.value === values.status)}
                      onChange={(e) => setFieldValue('status', e.value)}
                      errorText={errors.status && touched.status ? errors.status : null}
                      required
                    />
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-6">
                  <MultiLanguageInput
                    type="textarea"
                    label="Description"
                    id="category-description-text"
                    name="description"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                    required
                  />
                </div>
                <div className="mt-6 grid grid-cols-1 gap-6">
                  <label htmlFor="product-image-add" className="form-label">
                    Image <span style={{ color: 'red' }}>*</span>
                  </label>
                  {isImage ? (
                    <div className="h-1/2 w-1/2 m-auto">
                      <PreviewImage
                        src={values.image}
                        isRemovable={true}
                        onRemove={() => {
                          setFieldValue('image', '');
                          setIsImage(false);
                        }}
                      />
                    </div>
                  ) : (
                    <CropImage
                      src={values.image}
                      onChange={(file) => setFieldValue('image', file)}
                      errorText={touched.image && errors.image ? errors.image : null}
                      ref={cropImageRef}
                      path={`company/${companyId}/modifier/mod_`}
                      onUploadSuccess={onUploadSuccess}
                      onUploadError={onUploadError}
                      minCrop={IMG_UPLOAD.MODIFIER_IMAGE.MIN_RESOLUTION}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }}
    </OffCanvasForm>
  )
}

ModifierForm.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    modItemName: PropTypes.object.isRequired,
    description: PropTypes.object,
    status: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    posId: PropTypes.string.isRequired,
    sortOrder: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
}
export default ModifierForm
