import { useState, useEffect } from 'react'
import PageHeader from '@common/src/pageheader/pageheader'
import Pagination from '@hub/components/common/Pagination/pagination'
import { GET_ALL_PRODUCTS } from '@common/src/lib/graphql/query/product'
import { useQuery } from '@apollo/client'
import AvatarImage from '@hub/components/common/ImageHolders/avatarImage'
import SearchBox from '@common/src/common/inputbox/searchBox'
import { useNavigate } from 'react-router-dom'
import Toggle from '@common/src/common/Toggle/Toggle'
import { updateProduct } from '@common/src/service/cloud/catalog';
import { useToast } from '@common/src/common/Toast/ToastProvider';
import { useSelector } from 'react-redux'
import { calcOffset } from '@common/src/common/helper'
import FilterDropdown from '@common/src/common/inputbox/filterDrop'
import { ALL_OPTION, STATUS_OPTIONS } from '@hub/constants/constants'
import LoaderSm from '@common/src/common/loader/loader-sm'

const Productlist = () => {
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const { selectedCompany } = useSelector((state) => state.auth);
  const [rows, setRows] = useState([])
  const [searchQuery, setSearchQuery] = useState(params.get('search') || '');
  const [toggleLoader, setToggleLoader] = useState({ loading: false, id: null })

  const defaultPagination = {
    limit: parseInt(params.get('limit')) || 20,
    offset: parseInt(params.get('offset')) || 0,
    currentPage: parseInt(params.get('currentPage')) || 1,
    totalPages: 0,
    totalRecords: 0
  }
  const [prodStatus, setProdStatus] = useState('ALL');
  const [pagination, setPagination] = useState(defaultPagination)

  const [info, setInfo] = useState('No Products found.');
  const { data, refetch, loading } = useQuery(GET_ALL_PRODUCTS, {
    variables: {
      limit: parseInt(params.get('limit')) || pagination.limit,
      offset: calcOffset(searchQuery, parseInt(params.get('currentPage')) || pagination.currentPage, parseInt(params.get('limit')) || pagination.limit, pagination.totalRecords),
      search: params.get('search') || searchQuery || undefined,
      companyId: parseInt(selectedCompany?.value),
      status: params.get('status') || prodStatus
    },
  });

  useEffect(() => {
    if (data) {
      setRows(data.productListQuery.rows);
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: data.productListQuery.totalPages,
      }))
    } else {
      setRows([]);
      searchQuery && setInfo('No results found for your search.')
    }
  }, [data]);

  useEffect(() => {
    refetch({
      limit: parseInt(params.get('limit')) || pagination.limit,
      offset: calcOffset(searchQuery, parseInt(params.get('currentPage')) || pagination.currentPage, parseInt(params.get('limit')) || pagination.limit, pagination.totalRecords),
      search: params.get('search') || searchQuery || undefined,
      companyId: parseInt(selectedCompany?.value),
      status: params.get('status') || prodStatus
    });
    setPagination(defaultPagination);

  }, [searchQuery, selectedCompany, prodStatus, window.location.href]);


  // Function to handle the edit button click event
  const onEditClick = (data) => {
    // Navigate to the add product page with the product id as a query parameter
    navigate(`/add-product/?id=${data.id}`, {
      state: {
        // Pass the current URL as a redirect URL in the state
        redirectUrl: window.location.href.replace(window.location.origin, ''),
      },
    });
  }

  const onAssignClick = (data) => {
    navigate(`/store-assigning/?productId=${data.id}`, {
      state: {
        // Pass the current URL as a redirect URL in the state
        redirectUrl: window.location.href.replace(window.location.origin, ''),
      },
    })
  }

  const onStatusToggleClick = async (item) => {
    const newStatus = item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    setToggleLoader({ loading: true, id: item.id })
    try {
      await updateProduct({ id: item.id, companyId: parseInt(selectedCompany?.value), status: newStatus });
      // Update the state to reflect the change
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === item.id ? { ...row, status: newStatus } : row
        )
      );
      setToggleLoader({ loading: false, id: '' })
      toast.success(`Product status updated to ${newStatus.toLocaleLowerCase()}`, { type: 'success' });
    } catch (error) {
      setToggleLoader({ loading: false, id: '' })
      toast.error('Failed to update product status', { type: 'error' });
    }
  };
  // Function to handle the search input change
  const onSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);

    // If there is a query, set it as a search parameter
    if (query) {
      params.set('search', query);
    } else {
      // If the query is empty, remove the search parameter
      params.delete('search');
    }
    // Update the URL with the new offset and currentPage
    params.set('offset', 0);
    params.set('currentPage', 1);

    // Navigate to the new URL with the updated search parameters
    navigate(`?${params.toString()}`);
  };
  const onFilterChange = (e) => {
    // Update the search query state
    setProdStatus(e.value);

    // If there is a query, set it as a search parameter
    if (e.value) {
      params.set('status', e.value.toUpperCase());
    }
    // Update the URL with the new offset and currentPage
    params.set('offset', 0);
    params.set('currentPage', 1);

    // Navigate to the new URL with the updated search parameters
    navigate(`?${params.toString()}`);

  };
  return (
    <>
      <PageHeader currentpage="Menu" activepage="Menu Management" mainpage="Menu" />
      <div className="grid grid-cols-12 gap-6">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="box-header flex items-center justify-between">
              <div className="box-title">Menu Items</div>
              <div className="flex items-center">
                <FilterDropdown
                  className="mr-2 w-[200px]"
                  options={[ALL_OPTION.status, ...STATUS_OPTIONS]}
                  value={STATUS_OPTIONS.find((opt) => opt.value === prodStatus) || ALL_OPTION.status}
                  onChange={(e) => { onFilterChange(e) }}
                />
                <SearchBox
                  placeHolder="Search by item or POS ID"
                  value={searchQuery} onChange={(e) => onSearch(e.target.value)}
                  classes='!py-2 !px-2 !mr-2'
                />
              </div>
              <div className="flex items-center">
                <button type="button" className="ti-btn !py-2 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary ms-2 !mb-0" onClick={() => { navigate('/add-product') }}>
                  <i className="ri-add-line font-semibold align-middle me-1"></i>Add Item
                </button>
              </div>
            </div>
            <div className="box-body">
              <div className="table-responsive mb-4">
                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <thead>
                    <tr>
                      <th scope="col" className="text-start">
                        Sl.No
                      </th>
                      <th scope="col" className="text-start">
                        Item
                      </th>
                      <th scope="col" className="text-start">
                        Type
                      </th>
                      <th scope="col" className="text-start">
                        Price
                      </th>
                      <th scope="col" className="text-start">
                        Company
                      </th>
                      <th scope="col" className="text-start">
                        POS ID
                      </th>
                      <th scope="col" className="text-start">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {rows?.length > 0 ? (
                          rows.map((item) => (
                            <tr className="product-list" key={item.id}>
                              <td>{(pagination.currentPage - 1) * pagination.limit + rows.indexOf(item) + 1}</td>
                              <td>
                                <div className="flex items-center">
                                  <div className="me-2">
                                    <AvatarImage src={item?.image?.[0]} />
                                  </div>
                                  <div className="font-semibold">{item?.name?.en}</div>
                                </div>
                              </td>
                              <td>
                                <span className="badge bg-info text-white">{item?.type?.replace(/_/g, ' ')}</span>
                              </td>
                              <td>{item?.defaultSalesPrice}</td>
                              <td>{item?.company?.name?.en}</td>
                              <td>{item?.posId}</td>
                              <td>
                                <div className="flex items-center space-x-1 rtl:space-x-reverse">
                                  <button
                                    onClick={() => onEditClick(item)}
                                    className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm"
                                  >
                                    <i className="ri-pencil-line"></i>
                                  </button>
                                  <button
                                    onClick={() => onAssignClick(item)}
                                    className="ti-btn ti-btn-info ti-btn-icon ti-btn-sm"
                                  >
                                    <i className="ri-arrow-right-up-fill"></i>
                                  </button>
                                  <div className="relative inline-flex items-center">
                                    <Toggle label=""
                                      value={item.status === STATUS_OPTIONS[0].value}
                                      disabled={toggleLoader.loading && toggleLoader.id}
                                      onChange={() => onStatusToggleClick(item)} />
                                    {(toggleLoader.loading && toggleLoader.id === item.id) && (
                                      <div className="absolute inset-0 bg-opacity-50 bg-gray-100 w-full ml-3">
                                        <LoaderSm />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              <div className="alert alert-info">
                                {info}
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                pagination={pagination}
                setPagination={setPagination}
                rows={rows}
                searchQuery={searchQuery}
                status={prodStatus.toUpperCase()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Productlist
