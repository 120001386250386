export const loadImage = (uri, desiredWidth, desiredHeight) => {
  let finalWidth = 0
  let finalHeight = 0
  const image = new Image()
  image.src = uri

  return new Promise((resolve) => {
    image.onload = function () {
      const { width, height } = image

      const widthFit = desiredWidth / width // targetWidth / actual width
      const heightFit = desiredHeight / height // targetHeight / actual height
      const scale = widthFit > heightFit ? heightFit : widthFit

      finalWidth = width * scale
      finalHeight = height * scale

      // Adjust size to maintain aspect ratio if it's less than desired size
      if (finalWidth < desiredWidth) {
        const difference = desiredWidth / finalWidth
        finalWidth *= difference
        finalHeight *= difference
      }

      if (finalHeight < desiredHeight) {
        const difference = desiredHeight / finalHeight
        finalWidth *= difference
        finalHeight *= difference
      }

      // Calculate the percentage of scaling
      const initialScale = (finalWidth / width) * 100
      const orientation = height > width ? 'portrait' : 'landscape'

      resolve({
        image,
        height: finalHeight,
        width: finalWidth,
        scale: initialScale,
        orientation,
        actualSize: {
          width,
          height,
        },
      })
    }
  })
}



export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement('canvas')

  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      resolve(file)
    }, 'image/jpeg', 0.5)
  })
}
