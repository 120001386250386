import { useEffect, useMemo, useRef, useState } from 'react'
import { collection, doc, deleteDoc, query, getDocs, orderBy, where } from 'firebase/firestore'
import { useSelector } from 'react-redux'

import TableList from '@common/src/common/Table/TableList'
import PageHeader from '@common/src/pageheader/pageheader'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas.jsx'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas.js'
import { COLLECTIONS, STATUS } from '@common/src/constants/constants'

import { CATALOG } from '@hub/constants/constants'
import { db } from '@hub/firebase/firebaseapi'
import NotificationForm from './notificationForm'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import PropTypes from 'prop-types'
import { getCdnUrl } from '@common/src/common/helper'
import TextBreaker from '@common/src/common/Table/textBreaker'

const NotificationImage = ({ image }) => {
  const [imageUrl, setImageUrl] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (image instanceof File) {
      const url = URL.createObjectURL(image)
      setImageUrl(url)
      setError(false)

      return () => {
        URL.revokeObjectURL(url)
      }
    } else if (typeof image === 'string' && image.trim() !== '') {
      const url = getCdnUrl(image)
      setImageUrl(url)
      setError(false)
    } else {
      setImageUrl('')
      setError(true)
    }
  }, [image])

  if (error || !imageUrl) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="w-12 h-12 bg-gray-100 rounded flex items-center justify-center">
          <span className="text-xs text-gray-500">No Image</span>
        </div>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-center h-full">
      <div className="w-20 h-20 rounded overflow-hidden">
        <img
          src={imageUrl}
          alt="Notification"
          className="w-full h-full object-contain"
          onError={() => setError(true)}
        />
      </div>
    </div>
  )
}



const getColums = (onEditClick, onDeleteClick) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name.en',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description.en',
      key: 'desc',
      render: (text) => (
        <TextBreaker text={text} />
      ),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => <NotificationImage image={image} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <>{text}</>,
    },
    {
      title: 'Action',
      key: 'action',
      className: 'text-start',
      render: (text, record) => (
        <div className="space-x-1 rtl:space-x-reverse">
          {/* <button
            onClick={() => onEditClick(record)}
            aria-label="button"
            type="button"
            className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm"
          >
            <i className="ri-pencil-line"></i>
          </button> */}
          <button
            onClick={() => onDeleteClick(record.id)}
            aria-label="button"
            type="button"
            className="ti-btn ti-btn-danger ti-btn-icon ml-5 ms-1 ti-btn-sm invoice-btn"
          >
            <i className="ri-delete-bin-5-line"></i>
          </button>
        </div>
      ),
    },
  ]
}
const pagination = {
  pageSize: 20,
}

const Notifications = () => {
  const tableRef = useRef(null)
  const { selectedCompany } = useSelector((state) => state.auth)
  const [dbRef, setDbRef] = useState(null)
  const [data, setData] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedNotificationId, setSelectedNotificationId] = useState(null)
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()
  const [showAlert, setShowAlert] = useState(false);
  const toast = useToast()

  useEffect(() => {
    if (selectedCompany) {
      setDbRef(collection(db, `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${CATALOG.NOTIFICATION}`))
    }
  }, [selectedCompany])

  const onEditClick = (data) => {
    setData(data)
    setIsEdit(true)
    openOffCanvas('addNotification', 'Edit Push Notification')
  }

  const onDeleteClick = (id) => {
    setSelectedNotificationId(id)
    setIsModalVisible(true) // Show the confirmation modal
  }

  const handleDelete = async () => {
    try {
      const notificationRef = doc(
        db,
        `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${CATALOG.NOTIFICATION}`,
        selectedNotificationId
      )
      await deleteDoc(notificationRef)
      toast.success('Notification deleted successfully')

      // Refresh the table to reflect the changes
      if (tableRef.current) {
        tableRef.current.refreshCurrentPage()
      }

      setIsModalVisible(false) // Close the modal after successful deletion
    } catch (error) {
      toast.error('Error deleting notification')
      console.error('Error deleting notification: ', error)
      setIsModalVisible(false) // Close the modal in case of an error
    }
  }

  const columns = useMemo(() => getColums(onEditClick, onDeleteClick), [onEditClick, onDeleteClick])

  const onComplete = () => {
    if (tableRef.current) {
      tableRef.current.refreshCurrentPage()
      closeOffCanvas()
    }
  }

  const onClose = () => {
    closeOffCanvas(false)
  }
  const onAddClick = () => {
    setData(null)
    setIsEdit(false)
    openOffCanvas('addNotification', 'Add Push Notification')
  }

  const handleNotificationTypeChange = async (type) => {
    try {
      let collection_name = ''

      if (type === 'CATEGORY') {
        collection_name = COLLECTIONS.CATEGORIES
      } else if (type === 'PRODUCT') {
        collection_name = COLLECTIONS.PRODUCTS
      }

      if (!selectedCompany) {
        console.error('Please select a company first.')
        return null
      }

      const dbRef = collection(db, `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${collection_name}`)

      const dataQuery = query(dbRef, where('status', '==', STATUS.ACTIVE), orderBy('createdAt'))
      const dataSnapShot = await getDocs(dataQuery)
      const data = dataSnapShot.docs.map((doc) => ({
        value: `${type}/${doc.id}`,
        label: doc.data().name?.en,
      }))

      return data
    } catch (error) {
      console.error('Error fetching banner type data:', error)
      throw error
    }
  }

  return (
    <>
      <PageHeader currentpage="Promotions" activepage="Promotions" mainpage="Push Notifications" />
      {/* Success Alert Box */}
      {showAlert && (
        <div className="alert alert-warning  fade show flex" role="alert" id="dismiss-alert1">
          <div className="sm:flex-shrink-0">
            <strong>Push Notification</strong> created successfully.
          </div>
          <div className="ms-auto">
            <div className="mx-1 my-1">
              <button
                onClick={setTimeout(() => {
                  setShowAlert(false);
                }, 5000)}
                type="button"
                className="inline-flex bg-teal-50 rounded-sm text-teal-500 focus:outline-none focus:ring-0 
            focus:ring-offset-0 focus:ring-offset-teal-50 focus:ring-teal-600"
                data-hs-remove-element="#dismiss-alert1"
              >
                <span className="sr-only">Dismiss</span>
                <svg
                  className="h-3 w-3"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    d="M0.92524 0.687069C1.126 0.486219 1.39823 0.373377 1.68209 
      0.373377C1.96597 0.373377 2.2382 0.486219 2.43894 0.687069L8.10514 6.35813L13.7714 
      0.687069C13.8701 0.584748 13.9882 0.503105 14.1188 0.446962C14.2494 0.39082 14.3899
      0.361248 14.5321 0.360026C14.6742 0.358783 14.8151 0.38589 14.9468 0.439762C15.0782
      0.493633 15.1977 0.573197 15.2983 0.673783C15.3987 0.774389 15.4784 0.894026 15.5321 
      1.02568C15.5859 1.15736 15.6131 1.29845 15.6118 1.44071C15.6105 1.58297 15.5809 1.72357
      15.5248 1.85428C15.4688 1.98499 15.3872 2.10324 15.2851 2.20206L9.61883 7.87312L15.2851
      13.5441C15.4801 13.7462 15.588 14.0168 15.5854 14.2977C15.5831 14.5787 15.4705 14.8474 
      15.272 15.046C15.0735 15.2449 14.805 15.3574 14.5244 15.3599C14.2437 15.3623 13.9733 
      15.2543 13.7714 15.0591L8.10514 9.38812L2.43894 15.0591C2.23704 15.2543 1.96663 15.3623 
      1.68594 15.3599C1.40526 15.3574 1.13677 15.2449 0.938279 15.046C0.739807 14.8474 0.627232
      14.5787 0.624791 14.2977C0.62235 14.0168 0.730236 13.7462 0.92524 13.5441L6.59144 
      7.87312L0.92524 2.20206C0.724562 2.00115 0.611816 1.72867 0.611816 1.44457C0.611816 
      1.16047 0.724562 0.887983 0.92524 0.687069Z"
                    fill="currentColor"
                  />

                </svg>
              </button>
            </div>
          </div>
        </div>
      )}

      {dbRef && (
        <TableList
          name="Push Notifications"
          columns={columns}
          dbRef={dbRef}
          pagination={pagination}
          tools={{
            btnName: 'Add Push Notification',
            btnClick: onAddClick,
          }}
          ref={tableRef}
        />
      )}

      <FloatingCanvas onClose={onClose} isVisible={isVisible} close={closeOffCanvas} title={offCanvasTitle}>
        {activeComponent === 'addNotification' && (
          <NotificationForm data={data} isEdit={isEdit} onComplete={onComplete} showAlert={setShowAlert} onNotificationTypeChange={handleNotificationTypeChange} />
        )}
      </FloatingCanvas>
      {isModalVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h3 className="text-lg font-semibold text-center">Are you sure you want to delete this notification?</h3>
            <div className="mt-4 flex justify-center space-x-4">
              <button onClick={handleDelete} className="bg-danger text-white px-4 py-2 rounded hover:bg-red-700">
                Delete
              </button>
              <button
                onClick={() => setIsModalVisible(false)}
                className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
NotificationImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.string]).isRequired,
}
export default Notifications
