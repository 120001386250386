// User types
export const USER_TYPES = {
  HUB_ADMIN: 'HUB_ADMIN',
  HUB_SUBADMIN: 'HUB_SUBADMIN',
  HUB_PULSE_SUBADMIN: 'HUB_PULSE_SUBADMIN',
}

// Firestore collection names
export const COLLECTIONS = {
  USERS: 'users',
  ROLES: 'roles',
  COMPANIES: 'companies',
  BANNERS: 'banners',
  HOME: 'home',
  STORES: 'stores',
  NOTIFICATIONS: 'notifications',
  ORDERS: 'orders',
  PRODUCTS: 'products',
  ORDER_HISTORY: 'orderHistory',
  PAYMENT_HISTORY: 'paymentHistory',
}

export const PERMISSION = {
  ALL: 'all',
}

export const MENU = {
  TYPE_LINK: 'link',
  TYPE_SUB: 'sub',
}

export const ROLE = {
  ROLE_TYPE_HUB: 'HUB',
  ROLE_TYPE_PULSE: 'PULSE',
}

export const QUERY = {
  ORDER_BY_DESC: 'desc',
}

export const CATALOG = {
  PAGE_SIZE: 2,
  ID: '27',
  CATEGORY_COLLECTION: 'categories',
  MODIFIER_COLLECTION: 'modifiers',
  MODIFIER_GROUP_COLLECTION: 'modifierGroups',
  PRODUCT_COLLECTION: 'products',
  COMPANY_NOTIFICATIONS: 'companyNotifications',
  NOTIFICATION: 'notification',
}

export const CURRENCY_OPTIONS = [
  { value: 'INR', label: 'INR' },
  { value: 'AED', label: 'AED' },
  { value: 'RIY', label: 'RIY' },
]

export const STATUS_OPTIONS = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
]
export const DEFAULT_BANNER_OPTIONS = [
  { value: true, label: 'TRUE' },
  { value: false, label: 'FALSE' },
]
export const DEFAULT_BANNER_TYPES = [
  { value: 'CATEGORY', label: 'CATEGORY' },
  { value: 'PRODUCT', label: 'PRODUCT' },
]
export const DEFAULT_NOTIFICATION_TYPES = [
  { value: 'CATEGORY', label: 'CATEGORY' },
  { value: 'PRODUCT', label: 'PRODUCT' },
]
export const FOOD_OPTIONS = [
  { value: 'veg', label: 'VEG' },
  { value: 'non_veg', label: 'Non-Veg' },
  { value: 'egg', label: 'Egg' },
]

export const GROUP_TYPES = [
  { value: 'variant', label: 'Variant' },
  { value: 'addon', label: 'Add-On' },
]

export const TAX_TYPE_OPTIONS = [
  { value: 'VAT', label: 'VAT' },
  { value: 'GST', label: 'GST' },
  { value: 'NO_TAX', label: 'NO TAX' },
]
export const TAX_OPTIONS = [
  { value: 'INCLUSIVE', label: 'INCLUSIVE' },
  { value: 'EXCLUSIVE', label: 'EXCLUSIVE' },
]

export const PAYMENT_OPTIONS = [
  { value: 'COD', label: 'COD' },
  { value: 'ONLINE', label: 'ONLINE' },
]

export const SERVICE_OPTIONS = [
  {
    value: 'DELIVERY',
    label: 'Delivery',
  },
  {
    value: 'TAKEAWAY',
    label: 'Takeaway',
  },
  {
    value: 'GLOBAL_DELIVERY',
    label: 'Global Delivery',
  },
  {
    value: 'GLOBAL_TAKEAWAY',
    label: 'Global Takeaway',
  },
]

export const IMG_UPLOAD = {
  BANNER: {
    APP: {
      MIN_RESOLUTION: { WIDTH: 1200, HEIGHT: 514 },
      FORMATS: ['jpeg', 'png', 'jpg'],
      MAX_SIZE: '1 MB',
    },
    WEB: {
      MIN_RESOLUTION: { WIDTH: 1200, HEIGHT: 514 },
      FORMATS: ['jpeg', 'png', 'jpg'],
      MAX_SIZE: "1 MB"
    }
  },
  PRODUCT_IMAGE: {
    MIN_RESOLUTION: { WIDTH: 1080, HEIGHT: 1080 },
    FORMATS: ['jpeg', 'png', 'jpg'],
    MAX_SIZE: "1 MB"

  },
  CHEF_PICKS: {
    FORMATS: ['jpeg', 'png', 'jpg'],
    MAX_SIZE: "1 MB",
    homeWeb: {
      MIN_RESOLUTION: { WIDTH: 805, HEIGHT: 451 },
      FORMATS: ['jpeg', 'png', 'jpg'],
      MAX_SIZE: "1 MB"
    },
    homeMobile: {
      MIN_RESOLUTION: { WIDTH: 150, HEIGHT: 250 },
      FORMATS: ['jpeg', 'png', 'jpg'],
      MAX_SIZE: "1 MB"
    },
    categoryWeb: {
      MIN_RESOLUTION: { WIDTH: 1202, HEIGHT: 332 },
      FORMATS: ['jpeg', 'png', 'jpg'],
      MAX_SIZE: "1 MB"
    },
    categoryMobile: {
      MIN_RESOLUTION: { WIDTH: 336, HEIGHT: 186 },
      FORMATS: ['jpeg', 'png', 'jpg'],
      MAX_SIZE: "1 MB"
    }
  },

  CATEGORY_IMAGE: {
    MIN_RESOLUTION: { WIDTH: 100, HEIGHT: 100 },
    FORMATS: ['jpeg', 'png', 'jpg'],
    MAX_SIZE: "1 MB"
  },
  MODIFIER_IMAGE: {
    MIN_RESOLUTION: { WIDTH: 100, HEIGHT: 100 },
    FORMATS: ['jpeg', 'png', 'jpg'],
    MAX_SIZE: "1 MB"
  },
  STORE_IMAGE: {
    MIN_RESOLUTION: { WIDTH: 281, HEIGHT: 276 }
  },
  PUSH_NOTIFICATION_IMAGE: {
    MIN_RESOLUTION: { WIDTH: 360, HEIGHT: 180 },
  }
}
export const PRODUCT_ADD = {
  ALL: "ALL",
  SOME: "SOME",
  RANGE: "RANGE"
}
export const PROMO_CODE_TYPES = [
  { value: 'FLAT', label: 'Flat Amount Discount' },
  { value: 'PERCENTAGE', label: 'Percentage Discount' },
  { value: 'FREE_DELIVERY', label: 'Free Delivery' }
]

export const DEFAULT_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

export const DAY_OPTIONS = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
];
export const DAY_ABBREVIATIONS = {
  Monday: 'MON',
  Tuesday: 'TUE',
  Wednesday: 'WED',
  Thursday: 'THU',
  Friday: 'FRI',
  Saturday: 'SAT',
  Sunday: 'SUN',
};

export const CATEGORY_OPTIONS = [
  { value: 'DELIVERY', label: 'Delivery' },
  { value: 'TAKEAWAY', label: 'Takeaway' },
  { value: 'GLOBAL_DELIVERY', label: 'Global Delivery' },
  { value: 'GLOBAL_TAKEAWAY', label: 'Global Takeaway' },
]
export const ORDER_STATUS_OPTIONS = [
  { value: 'all', label: 'All Status' },
  { value: 'ACCEPTED', label: 'ACCEPTED' },
  { value: 'PLACED', label: 'PLACED' },
  { value: 'PREPARING', label: 'PREPARING' },
  { value: 'TRANSIT', label: 'TRANSIT' },
  { value: 'READY', label: 'READY' },
  { value: 'DELIVERED', label: 'DELIVERED' },
  { value: 'COMPLETED', label: 'COMPLETED' },
  { value: 'CANCELLED', label: 'CANCELLED' },
  { value: 'FAILED', label: 'FAILED' },
  { value: 'REFUND_INITIATED', label: 'REFUND INITIATED' },
  { value: 'REFUND_COMPLETED', label: 'REFUND COMPLETED' },
]

export const DISPUTE_ORDER_STATUS_OPTIONS = [

  { value: 'CANCELLED', label: 'CANCELLED' },
  { value: 'FAILED', label: 'FAILED' },
];


export const ALL_OPTION = {
  common:{ value: 'ALL', label: 'All' },
  status:{ value: 'all', label: 'All Status' },
  stores:{ value: 'all', label: 'All Stores' }
}


export const INITIAL_PAGINATION_PROPS = {
  limit: 20,
  offset: 0,
  currentPage: 1,
  totalPages: 0,
  totalRecords: 0,
}

export const DEFAULT_OPTIONS_STORE = [
  { value: true, label: 'Closed' },
  { value: false, label: 'Open' },
]
