
import React, { useEffect, useState } from 'react'
import SelectBox from '@common/src/common/selectbox/selectbox';
import { useToast } from '@common/src/common/Toast/ToastProvider';
import Pageheader from '@common/src/pageheader/pageheader'
import { updateCountryConfig } from '@common/src/service/cloud/settings';
import { getCompaniesByCountry, getCountries } from '@common/src/service/firebase/api';
import { Field, Form, Formik } from 'formik';
import Loader from '@common/src/common/loader/loader';


const DefaultCompany = () => {
    const toast = useToast();
    const [selectedCountry, setSelectedCountry] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [initialValues, setInitialValues] = useState({
        defaultCompanyId: selectedCountry?.defaultCompanyId || "",
    });

    // Fetch countries
    const fetchCountries = async () => {
        try {
            const countryList = await getCountries();
            const formattedData = countryList?.map((doc) => ({
                value: doc?.iso,
                iso: doc?.iso,
                label: doc?.name,
                id: doc?.id,
                defaultCompany: doc?.defaultCompanyId,
            }));
            const storedCountry = JSON.parse(localStorage.getItem("selectedCountry"));
            const selectedCountryFromStorage = storedCountry
            ? formattedData.find((country) => country.iso === storedCountry.iso)
            : null;
            const firstCountry = selectedCountryFromStorage || formattedData?.[0];
            setSelectedCountry(firstCountry);
            setInitialValues({
                defaultCompanyId: firstCountry?.defaultCompany || "",
            });
            setCountries(formattedData);
        } catch (error) {
            console.error("Failed to fetch countries:", error);
        }
    };

    // Fetch companies
    const fetchCompanies = async () => {
        if (selectedCountry?.iso) {
            try {
                const companyData = await getCompaniesByCountry(selectedCountry.iso);
                setCompanyOptions(companyData);
            } catch (error) {
                console.error("Failed to fetch companies:", error);
            }
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        fetchCompanies();
    }, [selectedCountry]);

    const onChange = (obj) => {
        setInitialValues({
            defaultCompanyId: obj.defaultCompany,
        });
        localStorage.setItem("selectedCountry", JSON.stringify(obj));
        setSelectedCountry(obj);
    };

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            values.country = selectedCountry.id;
            await updateCountryConfig(values);
            toast.success("Default company configuration updated successfully.");
            await fetchCountries();
            await fetchCompanies();
        } catch (error) {
            toast.error("Failed to update default company configuration.");
            console.error("Submission error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <Loader />;
    return (
        <>
            <Pageheader currentpage="Default Company" activepage="Default Company" mainpage="Settings" />
            <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-12 col-span-12">
                    <div className="box">
                        <div className="box-body add-products !p-0">
                            <div className="p-6">
                                <Formik
                                    enableReinitialize
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                >
                                    {({ setFieldValue, values, errors, touched }) => (
                                        <Form>
                                            <div className="grid-cols-12 gap-6 grid">
                                                <div className="xl:col-span-12 col-span-12">
                                                    <div className="grid-cols-1 gap-6 grid">
                                                        <div className="grid-cols-2 grid gap-6">
                                                            <label htmlFor="country" className="form-label">
                                                                SELECT COUNTRY
                                                            </label>
                                                            <SelectBox
                                                                id="country"
                                                                name="country"
                                                                className="ti-form-select rounded-sm !p-0"
                                                                placeholder="Choose Country"
                                                                options={countries}
                                                                value={selectedCountry || countries?.[0]}
                                                                onChange={onChange}
                                                                errorText={touched.country && errors.country ? errors.country : ''}
                                                            />
                                                        </div>
                                                    </div>
                                                    {selectedCountry && (
                                                        <div className="box">
                                                            {!isLoading && companyOptions?.length > 0 ? (
                                                                <>
                                                                    <div className="grid grid-cols-1 gap-6 mt-3">
                                                                        <label htmlFor="country" className="form-label">
                                                                            Select default company from below list
                                                                        </label>
                                                                    </div>
                                                                    <div className="grid grid-cols-2 gap-6">
                                                                        {companyOptions?.map((options, index) => (
                                                                            <button
                                                                                key={options.id}
                                                                                type="button"
                                                                                className="flex items-center bg-primary/20 p-3 cursor-pointer w-full rounded-sm"
                                                                                onClick={() =>
                                                                                    setFieldValue('defaultCompanyId', options?.value.toString())
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    type="radio"
                                                                                    name="defaultCompanyId"
                                                                                    value={options?.value.toString()}
                                                                                    className="form-check-input cursor-pointer"
                                                                                    id={`radio-md-${index}`}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label ml-2 cursor-pointer"
                                                                                    htmlFor={`radio-md-${index}`}
                                                                                >
                                                                                    {options?.label || '-'}
                                                                                </label>
                                                                            </button>
                                                                        ))}
                                                                    </div>
                                                                    <div className="flex justify-end mt-3">
                                                                        <button
                                                                            type="submit"
                                                                            disabled={isLoading}
                                                                            className="ti-btn ti-btn-primary ti-btn-loader m-1"
                                                                        >
                                                                            {isLoading ? <span className="me-2">Loading</span> : 'Save'}
                                                                            {isLoading && (
                                                                                <span className="loading">
                                                                                    <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="h-[calc(100vh-150px)] flex items-center justify-center">
                                                                    <p className="text-center mt-3 text-gray-500">No Companies Available</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default DefaultCompany