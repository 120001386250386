// User types
export const ROLE = {
  ROLE_TYPE_HUB: 'HUB',
  ROLE_TYPE_PULSE: 'PULSE',
}
export const USER_TYPES = {
  HUB_ADMIN: 'HUB_ADMIN', // super admins hub
  PULSE_ADMIN: 'PULSE_ADMIN', // super admins pulse
  PULSE_SUB_ADMIN: 'PULSE_SUB_ADMIN',
  HUB_SUB_ADMIN: 'HUB_SUB_ADMIN',
  HUB_PULSE_SUBADMIN: 'HUB_PULSE_SUBADMIN',
}
export const SUBADMIN_TYPES = {
  [ROLE.ROLE_TYPE_HUB]: [USER_TYPES.HUB_SUB_ADMIN, USER_TYPES.HUB_PULSE_SUBADMIN],
  [ROLE.ROLE_TYPE_PULSE]: [USER_TYPES.PULSE_SUB_ADMIN, USER_TYPES.HUB_PULSE_SUBADMIN],
}
export const PORTAL_USER_TYPES = {
  [ROLE.ROLE_TYPE_HUB]: [USER_TYPES.HUB_ADMIN, USER_TYPES.HUB_SUB_ADMIN],
  [ROLE.ROLE_TYPE_PULSE]: [USER_TYPES.PULSE_ADMIN, USER_TYPES.PULSE_SUB_ADMIN],
}
export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
}
// User
export const USER = {
  DEFAULT_PASSWORD: 'Test@123',
}

// Firestore collection names
export const COLLECTIONS = {
  USERS: 'users',
  ROLES: 'roles',
  MASTER_PERMISSIONS: 'masterPermissions',
  COMPANIES: 'companies',
  COUNTRIES: 'countries',
  BANNERS: 'banners',
  PAYMENT_CONFIGS: 'paymentConfigs',
  CATEGORIES: 'categories',
  ORDERS: 'orders',
  STORES: 'stores',
  PRODUCTS:'products',
  ORDER_HISTORY: 'orderHistory',
  PAYMENT_HISTORY: 'paymentHistory',
}
export const QUERY = {
  ORDER_BY_DESC: 'desc',
}
export const CLOUD_FUNCTIONS = {
  CREATE_HUB_SUBADMINS: 'api-accounts-createUser',
  UPDATE_HUB_SUBADMINS: 'api-accounts-updateUser',
  CREATE_CATEGORY: 'api-accounts-createCategory',
  UPDATE_CATEGORY: 'api-accounts-updateCategory',
  UPDATE_HUB_ROLE: 'api-accounts-updateRole',
  CREATE_MODIFIER: 'api-accounts-createModifier',
  UPDATE_MODIFIER: 'api-accounts-updateModifier',
  CREATE_MODIFIER_GROUP: 'api-accounts-createModifierGroup',
  UPDATE_MODIFIER_GROUP: 'api-accounts-updateModifierGroup',
  CREATE_COMPANY: 'api-accounts-createCompany',
  UPDATE_COMPANY: 'api-accounts-updateCompany',
  UPDATE_COMPANY_STATUS: 'api-accounts-updateCompanyStatus',
  CREATE_STORE: 'api-store-createStore',
  UPDATE_STORE: 'api-store-updateStore',
  UPDATE_STORE_STATUS: 'api-store-updateStoreStatus',
  CREATE_PRODUCT: 'api-products-createProduct',
  TRANSLATE: 'api-translation-translate',
  ASSIGN_PRODUCT: 'api-products-assignProductToStore',
  UPDATE_PRODUCT: 'api-products-updateProduct',
  UPDATE_ORDER: 'api-order-updateOrder',
  FORGOT_PASSWORD: 'api-accounts-forgotPassword',
  RESEND_INVITE_MAIL:'api-accounts-resendLink',
  INITIATE_REFUND: 'api-order-initiateRefund',
  UPDATE_COUNTRY_CONFIG: 'api-config-updateCountryConfig',
}
export const CALLBLE_HEADER = {
  mode: 'no-cors',
  headers: {
    'Accept-Language': 'en',
  },
}
export const COMPANY_ID = 27
export const MENU = {
  TYPE_LINK: 'link',
  TYPE_SUB: 'sub',
}

export const PROMOTIONS = {
  PROMOCODES: 'promocodes'
}
