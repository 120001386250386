import React from 'react';
import PropTypes from 'prop-types';

const TextBreaker = ({ text }) => (
    <span style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
        {text}
    </span>
);

TextBreaker.propTypes = {
    text: PropTypes.string.isRequired,
};

export default TextBreaker;
