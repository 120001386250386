import { useEffect, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg, { loadImage } from '../helper'
import PropTypes from 'prop-types'

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180
}

function rotateSize(width, height, rotation) {
  const rotRad = degreesToRadians(rotation)

  return {
    boxWidth: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    boxHeight: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

const ImageCropper = ({ source, height, width, zoom, rotation, onCrop, onZoomChange, onRotationChange }) => {
  const [loading, setLoading] = useState(true)
  const [crop, setCrop] = useState({ x: 0, y: 0, width, height })
  const [size, setSize] = useState({ width: 0, height: 0 })
  const containerRef = useRef(null)

  const handleCrop = async (_, croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(source, croppedAreaPixels, 0)
      onCrop(croppedImage)
    } catch (error) {
      console.log(error)
    }
  }

  const calculateSize = async () => {
    setLoading(true)
    const containerRect = containerRef.current?.getBoundingClientRect()
    if (!containerRect) return

    const { width, height, orientation } = await loadImage(
      source,
      containerRect.width * (9 / 16),
      containerRect.height * (9 / 16)
    )

    const isPortrait = orientation === 'portrait'
    setSize({ width, height: isPortrait ? width : height })
    setLoading(false)
  }

  useEffect(() => {
    calculateSize()
  }, [source, containerRef])

  return (
    <div className="w-full h-full flex flex-col items-center justify-center" ref={containerRef}>
      {loading ? (
        <div>loading</div>
      ) : (
        <div className="relative mx-auto" style={{ width: size.width, height: size.height }}>
          <Cropper
            image={source}
            crop={crop}
            zoom={zoom}
            aspect={width / height}
            showGrid={false}
            rotation={rotation}
            onCropChange={(props) => setCrop({ ...props, width, height })}
            onZoomChange={onZoomChange}
            onRotationChange={onRotationChange}
            onCropComplete={handleCrop}
          />
        </div>
      )}
    </div>
  )
}

ImageCropper.propTypes = {
  source: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  onCrop: PropTypes.func.isRequired,
  onZoomChange: PropTypes.func.isRequired,
  onRotationChange: PropTypes.func.isRequired,
}
export default ImageCropper
