// AuthContext.jsx
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './firebaseapi'
import { fetchUserDetailsRequest } from '../redux/modules/auth/action'
import { PORTAL_USER_TYPES } from '@common/src/constants/constants'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { user, companies = [], permission = [], loading } = useSelector((state) => state.auth)
  const [currentUser, setCurrentUser] = useState(null)
  const [type, setType] = useState(null)

  useEffect(() => {
    setType(user?.userType)
    setCurrentUser(user?.userType)
  }, [user, companies, permission])

  const contextValue = useMemo(
    () => ({ currentUser, permission, type, loading }),
    [currentUser, permission, type, loading]
  )
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user?.uid && auth?.currentUser) {
        const idTokenResult = await auth.currentUser.getIdTokenResult(true)
        const customClaims = idTokenResult?.claims
        if (PORTAL_USER_TYPES[import.meta.env.VITE_PORTAL]?.includes(customClaims.userType)) {
          dispatch(fetchUserDetailsRequest(user.uid))
        }
      }
    })
    return () => unsubscribe()
  }, [])

  return <AuthContext.Provider value={contextValue}>{!loading && children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
