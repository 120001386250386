import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * Renders a pagination component.
 *
 * @param {Object} pagination - The pagination object.
 * @param {function} setPagination - Function to set the pagination state.
 * @param {Array} rows - The rows data.
 *
 * @returns {React.ReactNode} The pagination component.
 */


const Pagination = ({ pagination, setPagination, rows, searchQuery, innerPages = true, status }) => {
    /**
     * Prepare the pagination state and update the URL based on the direction (prev/next).
     * @param {string} direction - The direction of the pagination.
     */
    const preparePagination = (direction) => {
        // If inner pages are enabled, update the URL params with the new pagination state
        if (innerPages) {
            const params = new URLSearchParams(window.location.search);
            // Set offset, currentPage, limit, and search query to the URL params
            params.set('offset', (direction === 'prev' ? pagination.offset - pagination.limit : pagination.offset + pagination.limit).toString());
            params.set('currentPage', (direction === 'prev' ? pagination.currentPage - 1 : pagination.currentPage + 1).toString());
            params.set('limit', pagination.limit.toString());
            if (searchQuery) params.set('search', searchQuery);
            if (status) params.set('status', status);
            // Update the URL with the new params
            window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
        }

        // Update the pagination state
        setPagination((prevPagination) => ({
            ...prevPagination,
            offset: direction === 'prev' ? prevPagination.offset - prevPagination.limit : prevPagination.offset + prevPagination.limit,
            currentPage: direction === 'prev' ? prevPagination.currentPage - 1 : prevPagination.currentPage + 1,
        }));
    }
    const handleNext = () => {
        if (pagination.currentPage === pagination.totalPages) return
        if (rows.length < pagination.limit) {
            return;
        }
        preparePagination('next')
    };

    const handlePrevious = () => {
        if (pagination.offset <= 0 || pagination.currentPage === 1) {
            return;
        }
        preparePagination('prev')
    };
    return (
        <div className="sm:flex items-center justify-between flex-wrap">
            <nav aria-label="Page navigation">
                <ul className="ti-pagination mb-0 flex flex-row rounded-sm text-[1rem] !ps-0">
                    <li className={`page-item ${pagination.currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link !text-primary !py-[0.375rem] !px-[0.75rem]" onClick={handlePrevious} disabled={pagination.currentPage === 1}>
                            Previous
                        </button>
                    </li>
                    <li className="page-item">
                        <Link className="page-link !text-primary !py-[0.375rem] !px-[0.75rem]" to="#">
                            {pagination?.currentPage}
                        </Link>
                    </li>
                    <li className={`page-item ${pagination.currentPage === pagination.totalPages}`}>
                        <button className="page-link !text-primary !py-[0.375rem] !px-[0.75rem]" onClick={handleNext} disabled={pagination?.currentPage === pagination?.totalPages} >
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    )
};

Pagination.propTypes = {
    rows: PropTypes.array.isRequired,
    pagination: PropTypes.shape({
        limit: PropTypes.number.isRequired,
        offset: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired
    }).isRequired,
    setPagination: PropTypes.func.isRequired,
    searchQuery: PropTypes.string,
    innerPages: PropTypes.bool,
    status: PropTypes.string
};

export default Pagination;

