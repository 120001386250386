import React, { useState, useEffect } from 'react';
import BasicDetials from './steps/BasicDetails';
import ImageUplaod from './steps/ImageUpload';
import Pricing from './steps/Pricing';
import Availability from './steps/Availability';
import Modifiers from './steps/modifierGroups/Modifiers';
import Configuration from './steps/Configuration';
import PageHeader from '@common/src/pageheader/pageheader';
import ChefPicks from './steps/ChefPicks';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_DETAILS } from '@common/src/lib/graphql/query/product';
import Loader from '@common/src/common/loader/loader';
import { useLocation } from 'react-router-dom';

const Stepper = () => {
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const { state } = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [productData, setProductData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editProductData, setEditProductData] = useState({});
  const [disableNavigation, setDisableNavigation] = useState(false);
  const [completedSteps, setCompletedSteps] = useState(Array(7).fill(false)); // Track completed steps
  const steps = ['Basic Details', 'Image Upload', 'Pricing', 'Modifiers', 'Chef\'s pick', 'Availability', 'Configuration'];

  const handleNext = () => {
    setCompletedSteps((prev) => {
      const newCompletedSteps = [...prev];
      newCompletedSteps[activeStep] = true;
      return newCompletedSteps;
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { data, loading, error: queryError, refetch } = useQuery(GET_PRODUCT_DETAILS, {
    variables: { id: parseInt(id) },
    skip: !id,
  });
  useEffect(() => {
    if (id) {
      refetch({ id: parseInt(id) });
    }
  }, [id, refetch]);

  useEffect(() => {
    if (data) {
      setIsEdit(true);
      const formattedProductData = { ...data.adminProductDetailsQuery };
      if (formattedProductData.productModGroups) {
        formattedProductData.productModGroups = formattedProductData.productModGroups.map((item) => ({
          modGroupId: item.id,
          modGroupName: item.modGroupName.en,
          modGroupType: item.modGroupType,
          quantity: item.quantity,
          sortOrder: item.sortOrder,
          modLabel: item.modLabel
        }));
      }
      setProductData(formattedProductData);
      setEditProductData({ id: data?.adminProductDetailsQuery.id });
    }
  }, [data]);

  if (loading || !productData) return <Loader />;
  if (queryError) return <p>Error loading product details</p>;

  return (
    <>
      <PageHeader currentpage="Add Product" activepage="Add Products" mainpage="Products" />
      <div className="flex justify-center space-x-4 my-4 bg-[#f3f4f6] dark:bg-[#171923]">
        <nav className="sm:flex sm:space-x-2 sm:rtl:space-x-reverse" aria-label="Tabs">
          {steps.map((step, index) => (
            <button
              type="button"
              key={step}
              className={
                index === activeStep
                  ? 'w-full sm:w-auto hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-4 px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap text-defaulttextcolor hover:text-primary dark:text-[#8c9097] dark:text-white/50 active'
                  : 'w-full sm:w-auto hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-4 px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap text-defaulttextcolor hover:text-primary dark:text-[#8c9097] dark:text-white/50'
              }
              onClick={() => setActiveStep(index)}
              id={`tabs-item-${index}`}
              data-hs-tab={`#tabs-${index}`}
              aria-controls={`tabs-${index}`}
              disabled={disableNavigation}
              style={{
                cursor: disableNavigation ? 'not-allowed' : 'pointer',
                opacity: disableNavigation ? 0.5 : 1,
              }}
            >
              {step}
            </button>
          ))}
        </nav>
      </div>
      {activeStep === 0 && <BasicDetials handleNext={handleNext} setProductData={setProductData} productData={productData} isEdit={isEdit} setEditProductData={setEditProductData} editProductData={editProductData} setDisableNavigation={setDisableNavigation} />}
      {activeStep === 1 && <ImageUplaod handleNext={handleNext} handleBack={handleBack} setProductData={setProductData} productData={productData} isEdit={isEdit} setEditProductData={setEditProductData} editProductData={editProductData} setDisableNavigation={setDisableNavigation} />}
      {activeStep === 2 && <Pricing handleNext={handleNext} handleBack={handleBack} setProductData={setProductData} productData={productData} isEdit={isEdit} setEditProductData={setEditProductData} editProductData={editProductData} setDisableNavigation={setDisableNavigation} />}
      {activeStep === 3 && <Modifiers handleNext={handleNext} handleBack={handleBack} setProductData={setProductData} productData={productData} isEdit={isEdit} setEditProductData={setEditProductData} editProductData={editProductData} setDisableNavigation={setDisableNavigation} />}
      {activeStep === 4 && <ChefPicks handleNext={handleNext} handleBack={handleBack} setProductData={setProductData} productData={productData} isEdit={isEdit} setEditProductData={setEditProductData} editProductData={editProductData} setDisableNavigation={setDisableNavigation} />}
      {activeStep === 5 && <Availability handleNext={handleNext} handleBack={handleBack} setProductData={setProductData} productData={productData} isEdit={isEdit} setEditProductData={setEditProductData} editProductData={editProductData} setDisableNavigation={setDisableNavigation} />}
      {activeStep === 6 && (
        <Configuration
          handleNext={handleNext}
          handleBack={handleBack}
          setProductData={setProductData}
          productData={productData}
          isEdit={isEdit}
          setEditProductData={setEditProductData}
          editProductData={editProductData}
          completedSteps={completedSteps}
          redirectUrl={state?.redirectUrl}
          setDisableNavigation={setDisableNavigation}
        />
      )}
    </>
  );
};

export default Stepper;