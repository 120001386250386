import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@hub/components/common/Pagination/pagination';
import Toggle from '@common/src/common/Toggle/Toggle';
import LoaderSm from '@common/src/common/loader/loader-sm';
import { STATUS_OPTIONS } from '@hub/constants/constants';
import TextBreaker from './textBreaker';

const DataTable = ({
  loading,
  rows,
  pagination,
  setPagination,
  onEditItem,
  onChangeStatus,
  toggleLoader,
  columns,
  info,
  innerPages
}) => (
  <div className="box-body">
    <div className="table-responsive mb-4">
      <table className="table whitespace-nowrap table-bordered min-w-full">
        <thead>
          <tr className="border-b border-defaultborder">
            <th className="text-start">Sl.No</th>
            {columns.map((column) => (
              <th key={column} className="text-start">{column}</th>
            ))}
          <th className="text-start">Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={8} className="text-center">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            <>
              {rows.length > 0 ? (
                rows.map((i) => (
                  <tr className="company-list border-b" key={i.id}>
                    <td>{(pagination.currentPage - 1) * pagination.limit + rows.indexOf(i) + 1}</td>
                    <td>
                      <div className="flex items-center">{i?.name?.en}</div>
                    </td>
                    <td>
                      <TextBreaker text={i?.description?.en} />
                    </td>
                    <td>
                      <div className="flex items-center">{i?.posId}</div>
                    </td>
                    <td>
                      <div className="flex items-center">{i?.sortOrder}</div>
                    </td>
                    <td>
                      <div className="flex items-center">{i?.status}</div>
                    </td>
                    <td>
                      <div className="space-x-1 rtl:space-x-reverse flex items-center">
                        <button onClick={() => onEditItem(i)} className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm">
                          <i className="ri-pencil-line"></i>
                        </button>
                        <div className="relative inline-flex items-center">
                          <Toggle label="" onChange={() => onChangeStatus(i)} value={i.status === STATUS_OPTIONS[0].value} />
                          {(toggleLoader.loading && toggleLoader.id === i.id) && (
                            <div className="absolute inset-0 bg-opacity-50 bg-gray-100">
                              <LoaderSm />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={8}>
                    <div className="alert alert-info">{info}</div>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
    <Pagination pagination={pagination} setPagination={setPagination} rows={rows} innerPages={innerPages} />
  </div>
);

DataTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  toggleLoader: PropTypes.object.isRequired,
  info: PropTypes.string.isRequired,
  innerPages: PropTypes.bool
};

export default DataTable;
