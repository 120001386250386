import React from 'react'
import PropTypes from 'prop-types'

const AddProductButton = ({ handleBack, handleNext, loadingBack, loadingNext, buttonText = 'Next' }) => {
  return (
    <div className="px-6 py-4 border-t border-dashed dark:border-defaultborder/10 sm:flex justify-end">
      {handleBack && (
        <button
          type="button"
          className="ti-btn ti-btn-primary !font-medium m-1"
          onClick={handleBack}
          disabled={loadingBack} // Disable button when back is loading
        >
          {loadingBack ? (
            <>
              <span className="me-2">Loading</span>
              <span className="loading">
                <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
              </span>
            </>
          ) : (
            'Previous'
          )}
        </button>
      )}
      <button
        type="submit"
        className="ti-btn ti-btn-success !font-medium m-1"
        onClick={handleNext}
        disabled={loadingNext} // Disable button when next is loading
      >
        {loadingNext ? (
          <>
            <span className="me-2">Loading</span>
            <span className="loading">
              <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
            </span>
          </>
        ) : (
          buttonText
        )}
      </button>
    </div>
  )
}
AddProductButton.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  loadingBack: PropTypes.bool,
  loadingNext: PropTypes.bool,
  buttonText: PropTypes.string,
}

export default AddProductButton
