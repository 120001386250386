import SelectBox from '@common/src/common/selectbox/selectbox'
import Toggle from '@common/src/common/Toggle/Toggle'
import Pageheader from '@common/src/pageheader/pageheader'
import { updateCountryConfig } from '@common/src/service/cloud/settings'
import { getCountries } from '@common/src/service/firebase/api'
import { useToast } from '@common/src/common/Toast/ToastProvider';

import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'

const HalalBadgeConfiguration = () => {
    const toast = useToast();
    const [selectedCountry, setSelectedCountry] = useState("")
    const [countries, setCountries] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [halal, setHalal] = useState(false)

    const [initialValues, setInitialValues] = useState({
        isHalal: selectedCountry.isHalal || false
    })


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [countryList] = await Promise.all([getCountries()])
                const getCountryData = countryList?.map((doc) => ({
                    value: doc?.iso,
                    label: doc?.name,
                    id: doc?.id,
                    isHalal: doc?.isHalal
                }));
                setCountries(getCountryData)
            } catch (error) {
                console.error('Failed to fetch data:', error)
            }
        }
        fetchData();
    }, [selectedCountry])


    const onChange = (obj) => {
        setInitialValues({
            isHalal: obj.isHalal || false
        })
        setHalal(obj.isHalal)
        setSelectedCountry(obj)
    }

    const handleSubmit = async (values) => {
        setIsLoading(true)
        values.country = selectedCountry.id
        await updateCountryConfig(values);
        toast.success("Halal badge configuration updated successfully");
        setIsLoading(false);
    }

    return (
        <>
            <Pageheader currentpage="Halal Badge Configuration" activepage="Halal Badge Configuration" mainpage="Settings" />

            <div className=" grid-cols-12 gap-6 grid">
                <div className="xl:col-span-12 col-span-12">
                    <div className="box">
                        <div className="add-products box-body  !p-0">
                            <div className="p-6">
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}

                                >
                                    {({ setFieldValue, values, errors, touched }) => (
                                        <Form>
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="xl:col-span-12 col-span-12">
                                                    <div className="grid grid-cols-1 gap-6">
                                                        <div className=" grid-cols-2 grid gap-6">

                                                            <label htmlFor="country" className="form-label">SELECT COUNTRY</label>
                                                            <SelectBox
                                                                id="country"
                                                                options={countries}
                                                                defaultValue={selectedCountry || countries?.[0]}
                                                                onChange={onChange}
                                                                errorText={touched.country && errors.country ? errors.country : ''}
                                                                name="country"
                                                                className="ti-form-select rounded-sm !p-0"
                                                                placeholder="Choose Country"
                                                            />
                                                        </div>
                                                    </div>
                                                    {selectedCountry &&
                                                        <>
                                                            <div className="xl:col-span-4 col-span-12">
                                                                <Toggle
                                                                    label="Enable Halal Badge"
                                                                    name="isHalal"
                                                                    value={halal}
                                                                    onChange={(e) => {
                                                                        setFieldValue("isHalal", e.target.checked);
                                                                        setHalal(e.target.checked);
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="flex justify-end">

                                                                <button disabled={isLoading} type="submit" className=" m-1 ti-btn ti-btn-primary ti-btn-loader">
                                                                    {isLoading ? <span className="me-2">Loading</span> : "Submit"}
                                                                    {isLoading && (
                                                                        <span className="loading">
                                                                            <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </>
                                                    }

                                                </div>

                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default HalalBadgeConfiguration