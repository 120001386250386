import {
    getDocs,
    query,
    collection
} from 'firebase/firestore'
import { COLLECTIONS } from '../../constants/constants'
import { db } from '@hub/firebase/firebaseapi'



export const getStore = async (selectedCompany) => {
  try {
    let dataQuery = query(collection(db, `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${COLLECTIONS.STORES}`))
    const productsSnapshot = await getDocs(dataQuery)
    const products = productsSnapshot.docs.map((doc) => ({
      ...doc.data(),

      id: doc.id,
    }))
    
    return products
  } catch (error) {
    console.error('Error fetching Category data:', error);
  }
};
