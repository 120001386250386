import { gql } from '@apollo/client';
export const GET_ALL_MODIFIERS = gql`
  query getAllModifiers($companyId: Int, $search: String, $limit: Int, $offset: Int, $status: String) {
    modifierQuery(companyId: $companyId, search: $search, limit: $limit, offset: $offset, status:$status) {
      limit
      offset
      totalPages
      totalRecords
      rows {
        id
        modItemName {
          ar
          en
        }
        description {
          ar
          en
        }
        image
        posId
       status
       sortOrder
       image
      }
    }
  }
`;
export const GET_ALL_CATEGORIES = gql`
  query getAllCategories($companyId: Int, $search: String, $limit: Int, $offset: Int, $status: String) {
    categoryQuery(companyId: $companyId, search: $search, limit: $limit, offset: $offset, status:$status) {
      limit
      offset
      totalPages
      totalRecords
      rows {
        id
        name {
          ar
          en
        }
        description {
          ar
          en
        }
          timed
          image
          services
          availability{
            date{
              start
              end
              option
            }
            day{
              days
              option
            }
            time{
              start
              end
              option  
            }
          }
        companyId
        posId
       status
       sortOrder
       isDefault
      }
    }
  }
`;



export const GET_ALL_MODIFIER_GROUPS = gql`
  query getAllModifierGroups($companyId: Int, $search: String, $limit: Int, $offset: Int, $status: String) {
    modifierGroupQuery(companyId: $companyId, search: $search, limit: $limit, offset: $offset, status:$status) {
      limit
      offset
      totalPages
      totalRecords
      rows {
        id
        modGroupName {
          ar
          en
        }
        modGroupType
        posId
       status
       sortOrder
       modLabel
       modifiers{
          id
          status
          price
        }
      }
    }
}`;