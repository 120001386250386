import { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import CropImage from '@common/src/common/ImageUpload/CropImage'
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'

import { STATUS_OPTIONS, SERVICE_OPTIONS, DEFAULT_OPTIONS, DAY_ABBREVIATIONS, PRODUCT_ADD, IMG_UPLOAD } from '@hub/constants/constants'
import { categoryAction } from '@common/src/service/cloud/catalog'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import "react-datepicker/dist/react-datepicker.css";
import TimedAvailability from '@hub/components/common/availability/TimedAvailability'
import PreviewImage from '@hub/components/common/ImageHolders/previewImage'
import { useSelector } from 'react-redux'
import { normalizeDateFormat } from '@common/src/common/helper'
import { availabilityValidationSchema } from '@hub/components/common/availability/validationSchema'

const validationSchema = Yup.object({
  status: Yup.string().required('Status is required'),
  posId: Yup.string().required('POS ID is required'),
  sortOrder: Yup.number()
    .typeError('Sort order is required')
    .min(1, 'Sort order must be greater than zero')
    .required('Sort order is required'),
  image: Yup.mixed()
    .test('image-or-url', 'Please upload an image', function (value) {
      const { imageUrl } = this.parent;
      return value || imageUrl; // Passes if either image or imageUrl is present
    })
    .test('file-format', 'Only jpeg, png, or jpg formats are allowed', function (value) {
      if (!value) return true; // Skip validation if no file is uploaded
      const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/jpg'];
      return SUPPORTED_FORMATS.includes(value.type); // Check the file type
    })
    .test('file-size', 'File size must be less than 1MB', function (value) {
      if (!value) return true; // Skip validation if no file is uploaded
      const MAX_SIZE = 1 * 1024 * 1024; // 1MB in bytes
      return value.size <= MAX_SIZE; // Check the file size
    }),
  services: Yup.array()
    .min(1, 'At least one service must be selected')
    .required('At least one service must be selected'),
  name: Yup.object()
    .shape({
      en: Yup.string().required('English name is required'),
      ar: Yup.string().required('Arabic name is required'),
    })
    .required('Name is required'),
  isDefault: Yup.boolean().required('Default status is required'),
  ...availabilityValidationSchema(), //common validation for availability
  });
const CategoryForm = ({ id, data, isEdit, onComplete, companyId }) => {
  const toast = useToast()
  const cropImageRef = useRef(null)
  const [isImage, setIsImage] = useState(!!data?.image);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedCompany } = useSelector((state) => state.auth)
  const [initialValues, setInitialValues] = useState({
    name: {},
    description: {},
    image: '',
    status: '',
    posId: '',
    sortOrder: '',
    imageUrl: '',
    services: [],
    isDefault: false,
    dateStart: null,
    dateEnd: null,
    timeStart: '',
    timeEnd: '',
    days: [],
    timed: false,
    dateAll: false, // Initialize new fields for select all
    timeAll: false,
    daysAll: false,
  })

  const getSingleCategoryData = async () => {
    setInitialValues({
      name: data?.name || {},
      description: data?.description || {},
      status: data?.status || '',
      posId: data?.posId || '',
      services: getFilteredServices(data?.services),
      imageUrl: data?.image || '',
      sortOrder: data?.sortOrder || '',
      isDefault: data?.isDefault || false,
      dateStart: data?.availability?.date?.start || null,
      dateEnd: data?.availability?.date?.end || null,
      timeStart: data?.availability?.time?.start || '',
      timeEnd: data?.availability?.time?.end || '',
      days: data?.availability?.day?.days?.map(day => Object.keys(DAY_ABBREVIATIONS).find(key => DAY_ABBREVIATIONS[key] === day)) || [],
      timed: data?.timed || false,
      dateAll: data?.availability?.date?.option === PRODUCT_ADD.ALL,
      timeAll: data?.availability?.time?.option === PRODUCT_ADD.ALL,
      daysAll: data?.availability?.day?.option === PRODUCT_ADD.ALL
    })
  }
  const getFilteredServices = (services) => SERVICE_OPTIONS.filter((item) => (services || []).includes(item.value))

  useEffect(() => {
    getSingleCategoryData()
  }, [])

  const onUploadError = (error) => {
    toast.error('Error uploading image')
    onComplete?.()
  }
  const onUploadStart = ({ actions }) => {
    console.log('action started')
    actions?.setSubmitting(true)
    setIsLoading(true)
  }
  const onUploadSuccess = async ({ values, actions, url = '' }) => {
    setIsLoading(true)
    const selectedValues = values.services ? values.services.map((option) => option.value) : []

    const formData = {
      ...values,
      companyId: companyId,
      services: selectedValues,
      image: url || values.imageUrl,
      ...(data?.id && { id: parseInt(data?.id) }),
      availableToday: true,
      isAvailableInStore:true,
      timeZone: selectedCompany?.timeZone,
    }
    if (values?.timed) {
      formData.availability = {
        date: {
          start: normalizeDateFormat(values.dateStart),
          end: normalizeDateFormat(values.dateEnd),
          option: values.dateAll ? PRODUCT_ADD.ALL : PRODUCT_ADD.RANGE,
        },
        day: {
          days: values.days.map(day => DAY_ABBREVIATIONS[day]),
          option: values.days.length === 7 ? PRODUCT_ADD.ALL : PRODUCT_ADD.SOME,
        },
        time: {
          start: values.timeStart,
          end: values.timeEnd,
          option: values.timeAll ? PRODUCT_ADD.ALL : PRODUCT_ADD.RANGE,
        },
      }
    }else{
      formData.availability=false
    }
    delete formData.dateStart;
    delete formData.dateEnd;
    delete formData.timeStart;
    delete formData.timeEnd;
    delete formData.days;
    delete formData.timeAll;
    delete formData.dateAll;
    delete formData.daysAll;
    try {
      await categoryAction(formData, isEdit)
      toast.success(isEdit ? 'Category updated successfully!' : 'Category added successfully!')
      actions?.resetForm()
      actions?.setSubmitting(false)
      HSOverlay.close(`#${id}`)
      onComplete?.()
    } catch (error) {
      toast.error(isEdit ? 'Error updating Category. Please ensure that the POS ID is unique.' : 'Error creating Category. Please ensure that the POS ID is unique.')
    }
    finally {
      setIsLoading(false)
    }
  }
  const handleSubmit = async (values, actions) => {
    actions?.setSubmitting(true)
    if (values.image) {
      cropImageRef.current.uploadImage()
    } else if (values.imageUrl) {
      await onUploadSuccess({ values, actions })
    }
  }

  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
      autoClose={false}
      loading={isLoading}
    >
      {({ setFieldValue, values, errors, touched }) => {
        return (
          <div className="grid grid-cols-12 gap-6">
            {/* Category Name and Starting Price */}
            <div className="xl:col-span-12 col-span-12">
              <div className="box">
                <div className="box-body add-products !p-0">
                  <div className="grid grid-cols-1 gap-6">
                    <MultiLanguageInput
                      type="input"
                      label="Category Name"
                      id="product-name-add"
                      name="name"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                      required = {true}
                      maxLength={20}
                    />
                  </div>
                  {/* Currency, Sort Order, POS ID, Status */}
                  <div className="grid grid-cols-2 gap-6">
                    <InputBox
                      label="POS ID"
                      name="posId"
                      type="text"
                      placeholder="POS ID"
                      value={values.posId}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/^\s+/, ''); // Remove leading spaces
                        setFieldValue('posId', sanitizedValue); // Update Formik's value
                      }}
                      required = {true}
                      maxLength={10}
                      errorText={errors.posId && touched.posId ? errors.posId : null}
                    />

                    <InputBox
                      label="Sort Order"
                      name="sortOrder"
                      type="number"
                      placeholder="Sort Order"
                      value={values.sortOrder}
                      onChange={(e) => {
                        setFieldValue('sortOrder', parseFloat(e.target.value))
                      }}
                      required = {true}
                      errorText={errors.sortOrder && touched.sortOrder ? errors.sortOrder : null}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <SelectBox
                      label="Status"
                      name="status"
                      options={STATUS_OPTIONS}
                      value={STATUS_OPTIONS.find((option) => option.value === values.status)}
                      onChange={(e) => {
                        setFieldValue('status', e.value)
                        console.log(errors)
                      }}
                      required = {true}
                      errorText={touched.status && errors.status ? errors.status : null}
                    />

                    <SelectBox
                      isMulti
                      label="Services"
                      className="form-control"
                      id="input-timezone"
                      name="services"
                      options={SERVICE_OPTIONS}
                      value={values.services}
                      onChange={(e) => {
                        setFieldValue('services', e)
                      }}
                      required = {true}
                      errorText={touched.services && errors.services ? errors.services : null}
                    />
                  </div>

                  {/* Description and Image */}
                  <div className="grid grid-cols-1 gap-6">
                    <MultiLanguageInput
                      type="textarea"
                      label="Description"
                      id="category-description-text"
                      name="description"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6">
                    <SelectBox
                      name="isDefault"
                      label="Default"
                      options={DEFAULT_OPTIONS}
                      value={DEFAULT_OPTIONS.find((option) => option.value === values.isDefault)}
                      onChange={(e) => setFieldValue('isDefault', e.value)}
                      id="isDefault"
                      errorText={touched.isDefault && errors.isDefault ? errors.isDefault : null}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6">
                    {/* Timed Availability */}

                  <TimedAvailability
                  errors={errors}
                  touched={touched}
                  />
                  </div>
                  <div className="grid grid-cols-1 gap-6">
                  <label htmlFor="product-image-add" className="form-label pt-5">
                    Image <span style={{ color: 'red' }}>*</span>
                  </label>
                  {isImage ? (
                    <div className="h-1/2 w-1/2 m-auto">
                      <PreviewImage
                        src={values.imageUrl}
                        isRemovable={true}
                        onRemove={() => {
                          setFieldValue('imageUrl', '');
                          setIsImage(false);
                        }}
                      />
                    </div>
                  ) : (
                    <CropImage
                      value={values.image}
                      onChange={(file) => setFieldValue('image', file)}
                      ref={cropImageRef}
                      path={`company/${companyId}/category/cat_`}
                      onUploadSuccess={onUploadSuccess}
                      onUploadStart={onUploadStart}
                      onUploadError={onUploadError}
                      minCrop={IMG_UPLOAD.CATEGORY_IMAGE.MIN_RESOLUTION}
                      errorText={errors.image ? errors.image : null}
                      error={errors.image ? errors.image : null}
                    />
                  )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </OffCanvasForm>
  )
}
CategoryForm.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.object.isRequired,
    description: PropTypes.object,
    status: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    posId: PropTypes.string.isRequired,
    sortOrder: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    services: PropTypes.arrayOf(PropTypes.string),
    isDefault: PropTypes.bool,
    availability: PropTypes.object,
    timed: PropTypes.bool,
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,

}

export default CategoryForm
