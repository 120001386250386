

// RowImage component - renders an image with a spinner while it's loading
import { getCdnUrl } from '@common/src/common/helper';
import PropTypes from 'prop-types';
const RowImage = ({ src,width = "250px", height = "100px", objectFit = "cover" }) => {

    return (
        <div className="relative"
        style={{ width, height, overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <img 
                src={getCdnUrl(src)} 
                className="hidden" 
                style={{ width: "100%", height: "100%", objectFit }}
                onLoad={(e) => {
                    // Remove hidden class when the image is loaded and remove spinner
                    e.target.classList.remove('hidden');
                    const parent = e.target.parentElement;
                    const imgSpin = parent.querySelector('.img-spin');
                    if (imgSpin) parent.removeChild(imgSpin);
                }}
                alt="" 
            />
            <div className="absolute inset-0 flex items-center justify-center img-spin">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
            </div>
        </div>
    )
}
RowImage.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    objectFit: PropTypes.string
};
export default RowImage;
